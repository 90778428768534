import * as React from "react";

const Audio = () => {
    return (
        <section class="audio basic-block" id="audio">
        <div class="container">
            <header class="audio_header basic-header mb-5 pb-5">
                <span class="basic-line">
                    <span class="basic-title my-3">Audio</span>
                </span>
            </header>
            <div class="audio-box">
                <div class="audio_album row">
                    <div class="album-player col col-12 col-sm-12 col-md-6 pb-5">
                        <iframe src="https://open.spotify.com/embed/album/3Y6QKVXZGH5qhLZXiIv6XV" width="100%" height="380" frameBorder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                    </div>
                    <div class="album-player col col-12 col-sm-12 col-md-6">
                        <iframe src="https://open.spotify.com/embed/album/0AdtwqLl2Pfos32Lj0Bo4h" width="100%" height="380" frameBorder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Audio;